<template>
  <v-dialog
    :value="value"
    scrollable
    persistent
    :overlay="false"
    max-width="800"
    transition="slide-y-transition"
  >
    <v-card>
      <v-card-title class="pos-r">
        詳細
        <v-spacer></v-spacer>
        <v-btn
          rounded
          color="Secondary100"
          fab
          x-small
          class="b-rad-12"
          @click="close"
        >
          <v-icon>mdi-window-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>

      <v-card-text>
        <Async :loading="isFetching">
          <SizeBox height="12" />
          <div>
            <div>
              <span class="Bold">平台訂單號：</span>
              {{ localData.platform_order_no || '-' }}
            </div>
            <div>
              <span class="Bold">商戶訂單號：</span>
              {{ localData.merchant_order_no || '-' }}
            </div>
          </div>
          <SizeBox height="12" />
          <p class="text-Heading5 Secondary900--text">詳細資訊</p>
          <div>
            <div>
              <span class="Bold">商戶名稱：</span>
              {{ localData.merchant.name }}
            </div>
            <div>
              <span class="Bold">商戶 ID：</span>
              {{ localData.merchant.serial }}
            </div>
            <div>
              <span class="Bold">付款人姓名：</span>
              {{ localData.payer_name }}
            </div>
          </div>

          <SizeBox height="22" />
          <p class="text-Heading5 Regular Secondary900--text">金額</p>

          <v-card flat color="Secondary100">
            <v-card-text>
              <v-row>
                <v-col cols="4" sm="2">訂單金額</v-col>
                <v-col>{{ localData.amt | currency }}</v-col>
                <v-col cols="12">
                  <v-divider></v-divider>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="4" sm="2">補單金額</v-col>
                <v-col>
                  <div class="d-flex flex-column flex-sm-row">
                    {{ localData.replenishment_amt | currency }}
                    <SizeBox width="56" />
                    <div>
                      <p class="d-flex">
                        校驗金額
                        <SizeBox width="24" />
                        {{ localData.verify_amt | currency }}
                      </p>
                      <p class="d-flex">
                        手續費
                        <SizeBox width="24" />
                        {{ localData.fee }}
                      </p>
                      <p class="d-flex">
                        用戶入賬
                        <SizeBox width="24" />
                        {{ localData.credit_amt | currency }}
                      </p>
                    </div>
                  </div>
                </v-col>
                <v-col cols="12">
                  <v-divider></v-divider>
                </v-col>
              </v-row>

              <v-row>
                <v-col>
                  <div>
                    建立時間
                    <SizeBox width="8" inline />
                    {{ localData.created_at | formatTime }}
                  </div>
                  <div>
                    成功時間
                    <SizeBox width="8" inline />
                    <span v-if="localData.succeeded_at">
                      {{ localData.succeeded_at | formatTime }}
                    </span>
                    <span v-else>未完成</span>
                  </div>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>

          <SizeBox height="22" />
          <p class="text-Heading5 Regular Secondary900--text">通道與其他資訊</p>

          <v-card flat color="Secondary100">
            <v-card-text>
              <v-row>
                <v-col cols="4" sm="2">通道</v-col>
                <v-col>{{ localData.collect_channel.name }}</v-col>
                <v-col cols="12">
                  <v-divider></v-divider>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="4" sm="2">通道子帳號</v-col>
                <v-col>{{ localData.card.name_1 }}</v-col>
                <v-col cols="12">
                  <v-divider></v-divider>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="4" sm="2">收款人姓名</v-col>
                <v-col>{{ localData.card.bank_holder }}</v-col>
                <v-col cols="12">
                  <v-divider></v-divider>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="4" sm="2">回調標示</v-col>
                <v-col>{{ localData.callback_note }}</v-col>
                <v-col cols="12">
                  <v-divider></v-divider>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="4" sm="2">用戶 IP</v-col>
                <v-col>{{ localData.client_ip }}</v-col>
                <v-col cols="12">
                  <v-divider></v-divider>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>

          <SizeBox height="22" />
          <p class="text-Heading5 Regular Secondary900--text">備註</p>

          <v-card flat color="Secondary100">
            <v-card-text>
              {{ localData.note || '無' }}
            </v-card-text>
          </v-card>

          <SizeBox height="22" />
          <!-- <p class="text-Heading5 Regular Secondary900--text">地址</p>

          <v-card flat color="Secondary100">
            <v-card-text>
              <v-row>
                <v-col cols="4" sm="2">網關地址</v-col>
                <v-col>https://tianne.com/apiurlssssss/adjioeq923jaad</v-col>
                <v-col cols="12">
                  <v-divider></v-divider>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="4" sm="2">同步通知地址</v-col>
                <v-col>
                  https://tianne.com/apiurlssssss/adjioeq923jaad-333
                </v-col>
                <v-col cols="12">
                  <v-divider></v-divider>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="4" sm="2">異步通知地址</v-col>
                <v-col>
                  https://tianne.com/apiurlssssss/adjioeq923j21s2ad-333
                </v-col>
                <v-col cols="12">
                  <v-divider></v-divider>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card> -->
        </Async>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { getCollectOrder } from '@/api/merchantCenter/collectOrders';
// import schema from './schema';

export default {
  props: {
    value: {
      type: Boolean,
      default: false
    },
    passData: {
      type: Object,
      default: () => ({
        /**
         *
         */
      })
    }
  },

  data() {
    return {
      isFetching: false,
      localData: {
        amt: 0,
        callback_note: '',
        payer_name: '',
        card: {
          id: '',
          name_1: '',
          bank_holder: ''
        },
        client_ip: '',
        collect_channel: {
          id: '',
          name: ''
        },
        created_at: '',
        credit_amt: 0,
        fee: 0,
        id: '',
        merchant: { serial: '', name: '' },
        merchant_order_no: '',
        platform_order_no: '',
        replenishment_amt: 0,
        succeeded_at: null,
        verify_amt: 0
      }
    };
  },

  watch: {
    value(v) {
      v ? this.init() : this.reset();
    }
  },

  methods: {
    init() {
      this.fetchData();
    },
    reset() {
      const originData = this.$options.data();
      Object.keys(originData).forEach((key) => {
        this.$set(this.$data, key, originData[key]);
      });
    },
    close() {
      this.$emit('close');
    },

    async fetchData() {
      this.isFetching = true;
      const Order = await getCollectOrder({
        id: this.passData.id
      });

      if (!Order.error) {
        this.$set(this, 'localData', { ...Order });
      }
      this.isFetching = false;
    }
  }
};
</script>

<style lang="scss" scoped>
//
</style>
