import { api } from "..";

export function placeOrder(payload) {
  return api('/v1/merchantCenter/collectOrders/placeOrder', payload);
}

export function getCollectOrder(payload) {
  return api('/v1/merchantCenter/collectOrders/getCollectOrder', payload);
}

export function getCollectOrderList(payload) {
  payload = { page: 1, limit: 20, ...payload }
  return api('/v1/merchantCenter/collectOrders/getCollectOrderList', payload);
}